import { createSlice } from "@reduxjs/toolkit";

// User Slice
const formAddressSlice = createSlice({
  name: "formaData",
  initialState: {
    status: false,
    formAddress: {},
  },
  reducers: {
    // Add Data
    AddFormData: (state, action) => {
      state.status = true;
      state.formAddress = action.payload;
    },
    // Clear data
    clearData: (state) => {
      state.status = true;
      state.formAddress = {};
    },
  },
});

const formReducer = formAddressSlice.reducer;
export default formReducer;
