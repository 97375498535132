import React from "react";
import img from "../../../assets/img/loader.gif";
import VideoLoader from "../../../assets/img/loader_2.mp4";
const Loader = () => {
  return (
    <>
      <div className="loader_wrapper">
        {/* <img width="200px" src={img} alt="loader" /> */}
        <video src={VideoLoader} autoPlay loop muted />
      </div>
    </>
  );
};

export default Loader;
