import {
  getDefaultMiddleware,
  configureStore,
  combineReducers,
} from "@reduxjs/toolkit";
import productsReducer from "./slices/products";
import settingsReducer from "./slices/settings";
import userReducer from "./slices/user";
import formReducer from "./slices/formAddress";
import api from "./middleware/api";
import logger from "./middleware/logger";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  products: productsReducer,
  user: userReducer,
  settings: settingsReducer,
  formData: formReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

// export const store = configureStore({
//   reducer: {
//     products: productsReducer,
//     user: userReducer,
//     settings: settingsReducer,
//   },
//   middleware: [...getDefaultMiddleware(), logger("console"), api],
// });

export const store = configureStore({
  reducer: persistedReducer,
  // devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk],
  // middleware: [...getDefaultMiddleware(), logger("console"), api],
});

export const persistor = persistStore(store);
