import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import { async } from "q";
// Demo Data
// import { apiCallBegan } from "../features/actions";
// import { API } from "../../http/API";

// Alert
import Swal from "sweetalert2";
import { API } from "../../http/API";

export const getAllCartData = createAsyncThunk(
  "pruducts/cartFetch",
  async () => {
    try {
      const tokenUser = localStorage.getItem("authtoken");
      const UserId = localStorage.getItem("UserId");
      const response = await API.get(`/auth/cart/${UserId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${tokenUser}`,
        },
      });
      return [...response.data];
    } catch (error) {
      return error.message;
    }
  }
);
// giftcart
export const getGiftCart = createAsyncThunk(
  "pruducts/giftCartFetch",
  async () => {
    try {
      const tokenUser = localStorage.getItem("authtoken");
      const UserId = localStorage.getItem("UserId");
      const response = await API.get(`/auth/gift-cart/${UserId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${tokenUser}`,
        },
      });
      return [response.data];
    } catch (error) {
      return error.message;
    }
  }
);
// get gift amount
export const GetGiftAmount = createAsyncThunk(
  "pruducts/GetGiftAmountFetch",
  async () => {
    try {
      const tokenUser = localStorage.getItem("authtoken");
      const UserId = localStorage.getItem("UserId");
      const response = await API.get(`/receiver-gift-card-total/${UserId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${tokenUser}`,
        },
      });
      return [response.data];
    } catch (error) {
      return error.message;
    }
  }
);
const tokenUser = localStorage.getItem("authtoken");

// get gift Card
export const GetGiftCard = createAsyncThunk(
  "pruducts/GetGiftCardFetch",
  async () => {
    try {
      const tokenUser = localStorage.getItem("authtoken");
      const UserId = localStorage.getItem("UserId");
      const response = await API.get(`/user-gift-cards/${UserId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${tokenUser}`,
        },
      });
      return [response.data];
    } catch (error) {
      return error.message;
    }
  }
);
// Product Slice
const productsSlice = createSlice({
  name: "products",
  initialState: {
    products: [],
    cart: [[]],
    total: [],
    isTotal: false,
    // gift card amount for user
    GiftCardAmount: [],
    // for user gift card in dashboard
    GiftCard: [],
    isGiftCard: false,
    promocode: [],
    isPromoCode: false,
    // gift cart when buying for someone
    cartGift: [[]],
    isGiftCart: false,
    UseGiftCardCheck: false,
  },
  reducers: {
    // Get Products
    productsReceived: (state, action) => {
      state.products = action.payload.data;
      //   // state.products = [{ name: "dummy", price: 76 },{ name: "dummy", price: 76 }];
    },
    //update to use or not use gift card amount
    UpdateGiftCardCheck: (state) => {
      state.UseGiftCardCheck = !state.UseGiftCardCheck;
    },
    // Update Total
    UpdateTotal: (state, action) => {
      state.total = action.payload;
      state.isTotal = true;
    },
    ClearTotal: (state) => {
      state.total = [];
      state.isTotal = false;
    },
    //promo Code
    AddPromoCode: (state, action) => {
      state.isPromoCode = true;
      state.promocode = action.payload;
    },
    ClearPromoCode: (state) => {
      state.isPromoCode = false;
      state.promocode = [];
    },
    // giftCart
    AddCartGift: (state, action) => {
      state.isGiftCart = true;
      state.cartGift = action.payload;
    },
    ClearCartGift: (state) => {
      state.isGiftCart = false;
      state.cartGift = [[]];
    },
    //cart
    // Add to Cart
    addToCart: (state, action) => {
      state.cart = action.payload;
      // Get Product
      // let arr = state?.products?.find((item) => item.id === parseInt(id));
      // arr.quantity = 1;
      // state.cart.push(item);
      // state.cart.push(action.payload);
      // state.cart[0].push(action.payload);

      Swal.fire({
        title: "Success!",
        text: "Successfully added to your Cart",
        icon: "success",
        showConfirmButton: false,
        timer: 2500,
      });
    },

    // Update Cart
    updateCart: (state, action) => {
      let { val, id } = action.payload;
      state.cart.forEach((item) => {
        if (item.id === parseInt(id)) {
          item.quantity = val;
        }
      });
    },

    // Remove Cart
    removeCart: (state, action) => {
      if (tokenUser) {
        let { id } = action.payload;
        let arr = state.cart.filter((item) => item.id !== parseInt(id));
        state.cart = arr;
      } else {
        state.cart = [[]];
      }
    },

    // Clear Cart
    clearCart: (state) => {
      state.cart = [[]];
    },
  },
  extraReducers: (builder) => {
    // All Cart Data
    builder.addCase(getAllCartData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllCartData.fulfilled, (state, action) => {
      if (!Array.isArray(action.payload)) {
        state.cart = [[]];

        return;
      }
      state.cart = [action.payload];

      state.loading = false;
    });
    builder.addCase(getAllCartData.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
    // Gift Cart Data
    builder.addCase(getGiftCart.pending, (state) => {
      state.isGiftCart = false;
    });
    builder.addCase(getGiftCart.fulfilled, (state, action) => {
      if (!Array.isArray(action.payload)) {
        state.isGiftCart = false;
        state.cartGift = [];
        return;
      }
      if (action.payload[0].error) {
        state.isGiftCart = false;
        state.cartGift = [];
        return;
      }
      state.cartGift = [action.payload];
      state.isGiftCart = true;
    });
    builder.addCase(getGiftCart.rejected, (state) => {
      state.isGiftCart = false;
      state.cartGift = [];
    });
    // Gift Cards
    builder.addCase(GetGiftCard.pending, (state) => {
      state.isGiftCard = false;
    });
    builder.addCase(GetGiftCard.fulfilled, (state, action) => {
      if (!Array.isArray(action.payload)) {
        state.isGiftCard = false;
        state.GiftCard = [];
        return;
      }
      if (action.payload[0].error) {
        state.isGiftCard = false;
        state.GiftCard = [];
        return;
      }

      if (action.payload[0][0]) {
        state.GiftCard = [action.payload];
        state.isGiftCard = true;
      }
    });
    builder.addCase(GetGiftCard.rejected, (state) => {
      state.isGiftCard = false;
      state.GiftCard = [];
    });
    // Gift Card Total Amount
    builder.addCase(GetGiftAmount.fulfilled, (state, action) => {
      state.GiftCardAmount = [action.payload];
    });
    builder.addCase(GetGiftAmount.rejected, (state) => {
      state.GiftCardAmount = [];
    });
  },
});

export const { addToCart, productsReceived } = productsSlice.actions;
const productsReducer = productsSlice.reducer;
export default productsReducer;

// export const loadproducts = (currentLang) => (dispatch, getState) => {
//   dispatch(
//     apiCallBegan({
//       url: `/front-products?lang=${currentLang}`,
//       onSuccess: productsReceived.type,
//     })
//   );
// };
