import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  BrowserRouter,
} from "react-router-dom";
import loadable from "./component/Common/loader/loadable";
import Loading from "./component/Common/loader";
import pMinDelay from "p-min-delay";

// ---------------------All Page Lazy Import start--------------------

const Home = loadable(() => pMinDelay(import("./page/"), 250), {
  fallback: <Loading />,
});
const SkinBeauty = loadable(() => pMinDelay(import("./page/skinbeauty"), 250), {
  fallback: <Loading />,
});
const Immunity = loadable(() => pMinDelay(import("./page/immunity"), 250), {
  fallback: <Loading />,
});
const AdviceOnTheUsage = loadable(
  () => pMinDelay(import("./page/adviceOnTheUsage"), 250),
  { fallback: <Loading /> }
);
const WhoWeAre = loadable(() => pMinDelay(import("./page/whoweare"), 250), {
  fallback: <Loading />,
});
const CelebrateYourEssence = loadable(
  () => pMinDelay(import("./page/celebrateyouressence"), 250),
  { fallback: <Loading /> }
);
const MeetTheCreator = loadable(
  () => pMinDelay(import("./page/meetthecreator"), 250),
  { fallback: <Loading /> }
);
const ClinicalStudies = loadable(
  () => pMinDelay(import("./page/clinicalStudies"), 250),
  { fallback: <Loading /> }
);
const UniquenessOfOurFormula = loadable(
  () => pMinDelay(import("./page/uniquenessOfOurFormula"), 250),
  { fallback: <Loading /> }
);
const SelectionOfIngredients = loadable(
  () => pMinDelay(import("./page/selectionOfIngredients"), 250),
  { fallback: <Loading /> }
);
const Sustainability = loadable(
  () => pMinDelay(import("./page/sustainability"), 250),
  { fallback: <Loading /> }
);
const loyaltyProgram = loadable(
  () => pMinDelay(import("./page/loyaltyProgram"), 250),
  { fallback: <Loading /> }
);
const Packaging = loadable(() => pMinDelay(import("./page/packaging"), 250), {
  fallback: <Loading />,
});
const PatentedMarineCollagenPeptides = loadable(
  () => pMinDelay(import("./page/patentedMarineCollagenPeptides"), 250),
  { fallback: <Loading /> }
);
const Faqs = loadable(() => pMinDelay(import("./page/faqs"), 250), {
  fallback: <Loading />,
});
// ---------------------All Page end--------------------

// ---------Shop Components start------------------------

const Shop = loadable(() => pMinDelay(import("./page/shop/index"), 250), {
  fallback: <Loading />,
});
const ProductDetails = loadable(
  () => pMinDelay(import("./page/product/index"), 250),
  { fallback: <Loading /> }
);
const Cart = loadable(() => pMinDelay(import("./page/cart/index"), 250), {
  fallback: <Loading />,
});

const Checkout = loadable(
  () => pMinDelay(import("./page/checkout/index"), 250),
  { fallback: <Loading /> }
);
const CheckoutGiftCard = loadable(
  () => pMinDelay(import("./page/checkout/CheckouGift"), 250),
  { fallback: <Loading /> }
);

// ---------Shop Components end------------------------

// ---------accounts Components start------------------------
const Login = loadable(() => pMinDelay(import("./page/login"), 250), {
  fallback: <Loading />,
});
const Register = loadable(() => pMinDelay(import("./page/register"), 250), {
  fallback: <Loading />,
});
const Verification = loadable(
  () => pMinDelay(import("./page/verification"), 250),
  {
    fallback: <Loading />,
  }
);
const Accounts = loadable(() => pMinDelay(import("./page/account"), 250), {
  fallback: <Loading />,
});
const Order = loadable(() => pMinDelay(import("./page/account/order"), 250), {
  fallback: <Loading />,
});
const ViewOrder = loadable(
  () => pMinDelay(import("./page/account/order-view"), 250),
  { fallback: <Loading /> }
);
const TrackOrder = loadable(
  () => pMinDelay(import("./page/account/order-tracking"), 250),
  { fallback: <Loading /> }
);
const ResetPassword = loadable(
  () => pMinDelay(import("./page/account/reset-password"), 250),
  { fallback: <Loading /> }
);
const Address = loadable(
  () => pMinDelay(import("./page/account/address"), 250),
  { fallback: <Loading /> }
);
const AddAddress = loadable(
  () => pMinDelay(import("./page/account/add-address"), 250),
  { fallback: <Loading /> }
);
const AccountDetails = loadable(
  () => pMinDelay(import("./page/account/account-details"), 250),
  { fallback: <Loading /> }
);
const GiftCard = loadable(
  () => pMinDelay(import("./page/account/gift-card"), 250),
  { fallback: <Loading /> }
);

// ---------accounts Components end------------------------

const OrderComplete = loadable(
  () => pMinDelay(import("./page/order/order-complete"), 250),
  { fallback: <Loading /> }
);
const OrderTracking = loadable(
  () => pMinDelay(import("./page/order/order-tracking"), 250),
  { fallback: <Loading /> }
);

const BlogGrid = loadable(() => pMinDelay(import("./page/blog/"), 250), {
  fallback: <Loading />,
});
const MediaGrid = loadable(
  () => pMinDelay(import("./page/blog/mediaGrid"), 250),
  { fallback: <Loading /> }
);
const MediaSingle = loadable(() =>
  pMinDelay(import("./page/media/media-single-page"))
);

const BlogSingleOnes = loadable(
  () => pMinDelay(import("./page/blog/blog-single-one"), 250),
  { fallback: <Loading /> }
);

const Error = loadable(() => pMinDelay(import("./page/error"), 250), {
  fallback: <Loading />,
});
const PrivacyPolicy = loadable(
  () => pMinDelay(import("./page/privacy-policy"), 250),
  { fallback: <Loading /> }
);

const TermsConditions = loadable(
  () => pMinDelay(import("./page/terms-conditions"), 250),
  { fallback: <Loading /> }
);

const ReturnPolicy = loadable(
  () => pMinDelay(import("./page/return-policy"), 250),
  { fallback: <Loading /> }
);

const RefundPolicy = loadable(
  () => pMinDelay(import("./page/refund-policy"), 250),
  { fallback: <Loading /> }
);

const Shipment = loadable(() => pMinDelay(import("./page/shipment"), 250), {
  fallback: <Loading />,
});

const ContactOne = loadable(() => pMinDelay(import("./page/contact"), 250), {
  fallback: <Loading />,
});
const ScrollToTop = loadable(
  () => pMinDelay(import("./component/Common/ScrollToTop"), 250),
  { fallback: <Loading /> }
);
const GiftCardComp = loadable(() =>
  pMinDelay(import("./page/GiftCard/GiftCard"))
);

const App = () => {
  useEffect(() => {
    const pathArray = window.location.pathname.split("/");
    let lang = "en";
    if (pathArray[1] && (pathArray[1] == "en" || pathArray[1] == "ar")) {
      lang = pathArray[1];
    }
    localStorage.setItem("currentLang", lang);
  }, []);

  return (
    <>
      <BrowserRouter>
        <Router>
          <ScrollToTop />
          <Switch>
            {/*--------------------------------Esme routes--------------------------- */}
            <Route path="/" exact component={Home} />
            <Route path="/en" exact component={Home} />
            <Route path="/ar" exact component={Home} />
            <Route path="/en/skin-beauty" exact component={SkinBeauty} />
            <Route path="/ar/skin-beauty" exact component={SkinBeauty} />
            <Route path="/en/immunity" exact component={Immunity} />
            <Route path="/ar/immunity" exact component={Immunity} />
            <Route path="/en/science" exact component={ClinicalStudies} />
            <Route path="/ar/science" exact component={ClinicalStudies} />
            <Route path="/en/usage-advice" exact component={AdviceOnTheUsage} />
            <Route path="/ar/usage-advice" exact component={AdviceOnTheUsage} />
            <Route
              path="/en/ingredients"
              exact
              component={SelectionOfIngredients}
            />
            <Route
              path="/ar/ingredients"
              exact
              component={SelectionOfIngredients}
            />
            <Route
              path="/en/unique-formula"
              exact
              component={UniquenessOfOurFormula}
            />
            <Route
              path="/ar/unique-formula"
              exact
              component={UniquenessOfOurFormula}
            />
            <Route path="/en/sustainability" exact component={Sustainability} />
            <Route path="/ar/sustainability" exact component={Sustainability} />
            <Route path="/en/packaging" exact component={Packaging} />
            <Route path="/ar/packaging" exact component={Packaging} />
            <Route
              path="/en/collagen-peptides"
              exact
              component={PatentedMarineCollagenPeptides}
            />
            <Route
              path="/ar/collagen-peptides"
              exact
              component={PatentedMarineCollagenPeptides}
            />
            <Route path="/en/Who-we-are" exact component={WhoWeAre} />
            <Route path="/ar/Who-we-are" exact component={WhoWeAre} />
            <Route
              path="/en/your-essence"
              exact
              component={CelebrateYourEssence}
            />
            <Route
              path="/ar/your-essence"
              exact
              component={CelebrateYourEssence}
            />
            <Route
              path="/en/meet-the-creator"
              exact
              component={MeetTheCreator}
            />
            <Route
              path="/ar/meet-the-creator"
              exact
              component={MeetTheCreator}
            />
            <Route
              path="/en/loyalty-program"
              exact
              component={loyaltyProgram}
            />
            <Route
              path="/ar/loyalty-program"
              exact
              component={loyaltyProgram}
            />
            <Route path="/en/faqs" exact component={Faqs} />
            <Route path="/ar/faqs" exact component={Faqs} />
            <Route path="/en/blogs" exact component={BlogGrid} />
            <Route path="/ar/blogs" exact component={BlogGrid} />
            <Route path="/en/blog/:id" exact component={BlogSingleOnes} />
            <Route path="/ar/blog/:id" exact component={BlogSingleOnes} />
            <Route path="/en/media" exact component={MediaGrid} />
            <Route path="/ar/media" exact component={MediaGrid} />
            <Route path="/en/media/:id" exact component={MediaSingle} />
            <Route path="/ar/media/:id" exact component={MediaSingle} />
            <Route path="/en/shop" exact component={Shop} />
            <Route path="/ar/shop" exact component={Shop} />
            <Route path="/en/shop/:id" exact component={ProductDetails} />
            <Route path="/ar/shop/:id" exact component={ProductDetails} />
            <Route path="/en/cart" exact component={Cart} />
            <Route path="/ar/cart" exact component={Cart} />
            <Route path="/en/checkout" exact component={Checkout} />
            <Route path="/ar/checkout" exact component={Checkout} />
            <Route path="/en/checkoutgift" exact component={CheckoutGiftCard} />
            <Route path="/ar/checkoutgift" exact component={CheckoutGiftCard} />
            <Route path="/en/order-complete" exact component={OrderComplete} />
            <Route path="/ar/order-complete" exact component={OrderComplete} />
            <Route path="/en/order-tracking" exact component={OrderTracking} />
            <Route path="/ar/order-tracking" exact component={OrderTracking} />
            <Route path="/en/gift-card" exact component={GiftCardComp} />
            <Route path="/ar/gift-card" exact component={GiftCardComp} />
            <Route path="/en/login" exact component={Login} />
            <Route path="/ar/login" exact component={Login} />
            <Route path="/en/register" exact component={Register} />
            <Route path="/ar/register" exact component={Register} />
            <Route path="/en/verification" exact component={Verification} />
            <Route path="/ar/verification" exact component={Verification} />
            <Route path="/en/account" exact component={Accounts} />
            <Route path="/ar/account" exact component={Accounts} />
            <Route path="/en/account/orders" exact component={Order} />
            <Route path="/ar/account/orders" exact component={Order} />
            <Route
              path="/en/account/view-order/:id"
              exact
              component={ViewOrder}
            />
            <Route
              path="/ar/account/view-order/:id"
              exact
              component={ViewOrder}
            />
            <Route
              path="/en/account/order-tracking/:id"
              exact
              component={TrackOrder}
            />
            <Route
              path="/ar/account/order-tracking/:id"
              exact
              component={TrackOrder}
            />
            <Route
              path="/en/account/reset-password"
              exact
              component={ResetPassword}
            />
            <Route
              path="/ar/account/reset-password"
              exact
              component={ResetPassword}
            />
            <Route path="/en/account/address" exact component={Address} />
            <Route path="/ar/account/address" exact component={Address} />
            <Route
              path="/en/account/add-address"
              exact
              component={AddAddress}
            />
            <Route
              path="/ar/account/add-address"
              exact
              component={AddAddress}
            />
            <Route
              path="/en/account/add-address/:id"
              exact
              component={AddAddress}
            />
            <Route
              path="/ar/account/add-address/:id"
              exact
              component={AddAddress}
            />
            <Route
              path="/en/account/account-details"
              exact
              component={AccountDetails}
            />{" "}
            <Route
              path="/ar/account/account-details"
              exact
              component={AccountDetails}
            />
            <Route path="/en/account/gift-cards" exact component={GiftCard} />
            <Route path="/ar/account/gift-cards" exact component={GiftCard} />
            <Route path="/en/privacy-policy" exact component={PrivacyPolicy} />
            <Route path="/ar/privacy-policy" exact component={PrivacyPolicy} />
            <Route
              path="/en/terms-conditions"
              exact
              component={TermsConditions}
            />
            <Route
              path="/ar/terms-conditions"
              exact
              component={TermsConditions}
            />
            <Route path="/en/return-policy" exact component={ReturnPolicy} />
            <Route path="/ar/return-policy" exact component={ReturnPolicy} />
            <Route path="/en/refund-policy" exact component={RefundPolicy} />
            <Route path="/ar/refund-policy" exact component={RefundPolicy} />
            <Route path="/en/shipment" exact component={Shipment} />
            <Route path="/ar/shipment" exact component={Shipment} />
            <Route path="/en/contact" exact component={ContactOne} />
            <Route path="/ar/contact" exact component={ContactOne} />
            <Route exact component={Error} />
          </Switch>
        </Router>
      </BrowserRouter>
    </>
  );
};

export default App;
